.sirdata-products {
  &__item {
    width: 23rem;
    height: 15rem;

    &__title {
      font-size: $font-size-16;
      font-weight: $font-weight-bold;
      color: var(--color-midnight-light)
    }

    &__price {
      background-color: var(--color-grey-light);
      border-radius: var(--border-radius-md);
      height: 5rem;

      p {
        color: var(--color-midnight-lighter);
      }

      span {
        font-size: $font-size-28;
        font-weight: $font-weight-semi-bold;
        color: var(--color-midnight-lighter);
      }
    }

    .status-label {
      color: var(--color-grey-dark);

      &__icon__bullet {
        width: 1rem;
        height: 1rem;
      }
    }

    &--active {
      .sirdata-products__item__price {
        span {
          color: var(--color-cyan);
        }
      }
    }
  }
}
