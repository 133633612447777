.onboarding {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-midnight);
  background: radial-gradient(circle, var(--color-midnight) 50%, var(--color-midnight-dark) 100%);
  overflow: auto;

  .register-page {
    padding: var(--spacing-xl) var(--spacing-lg);

    .register-form {
      max-width: 50rem;
      margin: auto;
      text-align: center;
      color: var(--color-grey);

      &--large {
        max-width: 80rem;
      }

      .main-logo {
        height: 50px;
        margin: 0 auto calc(var(--spacing-md) * 2);

        img,
        svg {
          height: 100%;
          width: auto;
        }
      }

      .title {
        margin-bottom: var(--spacing-md);
        color: var(--color-grey);

        & span {
          color: var(--color-green);
        }
      }

      .description {
        display: block;
        margin: 0 var(--spacing-sm) calc(var(--spacing-md) * 2);
        font-size: $font-size-18;
      }

      .register-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.terms {
          margin-top: var(--spacing-xs);
          flex-direction: column;
          align-items: flex-start;
          gap: var(--spacing-xs);
        }
      }

      a.right-link {
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-style: italic;
        text-decoration: none;
        text-align: right;
        font-size: $font-size-14;
        margin: 0 calc(var(--spacing-md) / 2);

        &:hover {
          text-decoration: underline;
        }
      }

      &__origin-group {
        width: 100%;

        h3 {
          color: var(--color-midnight);
        }
      }

      &__origin-items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--spacing-md);
        margin-top: var(--spacing-sm);

        @media #{$screen-s} {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      &__block {
        text-align: left;
      }
    }
  }
}

/* RESPONSIVE */
@media #{$screen-s}, #{$screen-m} {
  .onboarding {
    .register-page {
      .register-form {
        width: 100%;
      }
    }
  }
}
