.trial-information {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  background-color: var(--color-grey-light);
  border-color: var(--color-grey-light);
  height: fit-content;

  &__step {
    position: relative;
    display: flex;
    gap: var(--spacing-md);
    padding-bottom: var(--spacing-xs);
    
    p {
      color: var(--color-midnight-lighter);
    }

    &__title {
      font-weight: bold;
      margin-bottom: var(--spacing-xs);

      &--highlight {
        color: var(--color-cyan);
      }
    }

    &__link span {
      margin-top: var(--spacing-md);
      color: var(--color-ocean);
    }

    &:not(:last-child)::before {
      content: '';
      position: absolute;
      top: 2.5rem;
      bottom: 0;
      left: 0.75rem;
      width: 1px;
      background-color: var(--color-grey);
    }
  }
}
