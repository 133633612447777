.home-box {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);

  &__section {
    &__title {
      display: flex;
      align-items: center;
      gap: 1.2rem;
    }

    &__list {
      padding-left: calc(var(--spacing-md) * 2);

      & li {
        margin-top: var(--spacing-sm);
      }
    }
  }
}
