.action-add-payment-method {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  border: 1px solid var(--color-grey);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm) var(--spacing-md);
  width: fit-content;
  cursor: pointer;

  &:hover {
    background-color: var(--color-grey-light);
  }
}
